import base from './base';

export const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    backToOverview: 'Terug naar overzicht',
    proceedToCheckout: 'Verder met bestellen',
    changeFormula: 'Wijzig abonnement',
  },
};
